import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import { DashbordInnenTempChart, DashbordAusenTempChart, DashboardInnenTempSummary, DashboardAusenTempSummary } from './dashbordCharts';
import { Day, Week, Month, Year } from './Buttons';
import { TabPanel, useStyles, a11yProps } from './TopMenu';
import { SpecialTabPanel } from './rTabPanel';




export default function Main() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [innenTemperaturTime, setInnenTemperaturTime] = React.useState(0);
  const [innenTemperaturTimeScale, setInnenTemperaturTimeScale] = React.useState(0);
  const [ausenTemperaturTime, setAusenTemperaturTime] = React.useState(0);
  const [ausenTemperaturTimeScale, setAusenTemperaturTimeScale] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setInnenTemperaturTime(1);
    setInnenTemperaturTimeScale('hour');
    setAusenTemperaturTime(1);
    setAusenTemperaturTimeScale('hour');
  }, [])


  return (

    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Übersicht Luft" {...a11yProps(0)} />
          <Tab label="Webcam" {...a11yProps(1)} />
          <Tab label="Wind und Niederschlag" {...a11yProps(2)} />
          <Tab label="About" {...a11yProps(3)} />
          <Tab label="zurück" {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <h1>Temperatur und Luftfeuchte</h1>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>Luftmessungen vom ESP32</h3>
          </Grid>
          <Grid item xs={10}>
            <DashbordAusenTempChart number={ausenTemperaturTime} scale={ausenTemperaturTimeScale} />
          </Grid>
          <Grid item xs={2}>
            <div style={{ margin: '20%' }}>
              <Day variant="contained" color="primary" onClick={() => { setAusenTemperaturTime(1); setAusenTemperaturTimeScale('hour'); }}>
                1 Tag
            </Day>
              <p />
              <Week variant="contained" color="primary" onClick={() => { setAusenTemperaturTime(7); setAusenTemperaturTimeScale('day'); }}>
                1 Woche
            </Week>
              <p />
              <Month variant="contained" color="primary" onClick={() => { setAusenTemperaturTime(31); setAusenTemperaturTimeScale('week'); }}>
                1 Monat
            </Month>
              <p />
              <Year variant="contained" color="primary" onClick={() => { setAusenTemperaturTime(365); setAusenTemperaturTimeScale('month'); }}>
                1 Jahr
            </Year>
            </div>
          </Grid>
          <Grid item xs={12}><DashboardAusenTempSummary number={ausenTemperaturTime} scale={ausenTemperaturTimeScale} /></Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h1>Webcam Raspberry Pi Zero</h1>
        <br></br>
        <img src="https://wetter.bgutschi.at/webcam/newpic.jpg" alt="Bild vom Raspi"></img>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h1>Windgeschwidigkeit, Windrichtung und Niederschlag</h1>
        <br></br>
        <h2>Comming Soon - Dann mal</h2>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <h1>Über dieses Projekt</h1>
        Dieses Projekt ist ein Prototyp einer Wettersation. Es wurde keine vorgefertigten Plugins/Dependencies, speziell für Wetterstationen verwendet.
        Das Ziel ist die Verknüfung einer Website mit der Datenbank zu erlernen und sauber zu visualieren.
        Die Website basiert auf einem Framework - React.js. Somit ist zum aktualiesieren der Daten kein Neuladen der Seite nötig.
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SpecialTabPanel></SpecialTabPanel>
      </TabPanel>
    </div >
  );
}
