import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, blue, orange, red } from '@material-ui/core/colors';


export const Day = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
        width: 120,
    },
}))(Button);

export const Week = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
        width: 120,
    },
}))(Button);

export const Month = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(orange[500]),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
        width: 120,
    },
}))(Button);

export const Year = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
        width: 120,
    },
}))(Button);

