import React from 'react';
import { TabPanel } from './TopMenu';

export class SpecialTabPanel extends React.Component {
 componentDidMount(){     window.location.href = "https://bgutschi.at";  }
  render() {
    return (
      <TabPanel>
      <h2>Contact</h2>
     </TabPanel>
    )
  }
}
