
import React from 'react';
import { getInnenTemperaturEntry, getAusenTemperaturEntry, getAusenTemperaturSummary, getInnenTemperaturSummary } from './dbConnection';
import Chart from 'chart.js';
import { ReactComponent as Diameter } from './svg/Diameter.svg';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';



import Grid from '@material-ui/core/Grid';

Chart.defaults.global.defaultFontFamily = "Roboto, sans-serif";
Chart.defaults.global.animation.easing = 'easeOutCirc';
Chart.defaults.global.animation.duration = 100;

export class DashbordInnenTempChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getInnenTemperaturEntry(this.props.number),
      scale: this.props.scale
    };
  }


  componentDidUpdate() {
    if (this.state.scale !== this.props.scale) {
      this.setState({
        scale: this.props.scale,
        data: getInnenTemperaturEntry(this.props.number)
      });
    }
  }

  componentDidMount() {
    window.setInterval(() => {
      this.setState({
        data: getInnenTemperaturEntry(this.props.number)
      });
    }, 60000)
  }

  render() {
    return (
      <div>
        <LineChart
          data={this.state.data}
          scale={this.props.scale}
        />
      </div>

    );
  }
}

export class DashbordAusenTempChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getAusenTemperaturEntry(this.props.number),
      scale: this.props.scale
    };
  }


  componentDidUpdate() {
    if (this.state.scale !== this.props.scale) {
      this.setState({
        scale: this.props.scale,
        data: getAusenTemperaturEntry(this.props.number)
      });
    }
  }

  componentDidMount() {
    window.setInterval(() => {
      this.setState({
        data: getAusenTemperaturEntry(this.props.number)
      });
    }, 60000)
  }

  render() {
    return (
      <div>
        <LineChart
          data={this.state.data}
          scale={this.props.scale}
        />
      </div>

    );
  }
}

export class DashboardInnenTempSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getInnenTemperaturSummary(this.props.number),
      scale: this.props.scale
    };
  }


  componentDidUpdate() {
    if (this.state.scale !== this.props.scale) {
      this.setState({
        scale: this.props.scale,
        data: getInnenTemperaturSummary(this.props.number)
      });
    }
  }

  componentDidMount() {
    window.setInterval(() => {
      this.setState({
        data: getInnenTemperaturSummary(this.props.number)
      });
    }, 60000)
  }

  render() {
    return (
      <div>
        <Grid container spacing={3} >
          <Grid item xs={1} style={{ backgroundColor: '#fa4343' }}>
            <Diameter component={Diameter} style={{ margin: 'auto'}} />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
            <span style={{ backgroundColor: '#fa4343','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[0][0].avgT * 100)/100).toFixed(2)}°C </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
          <span style={{ backgroundColor: '#fa4343','display': 'block' , 'marginTop': 'auto'}} >
            <ArrowUpwardIcon /></span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
            <span style={{ backgroundColor: '#fa4343','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[1][0].maxT * 100)/100).toFixed(2)}°C </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343' }}>
          <ArrowDownwardIcon />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
            <span style={{ backgroundColor: '#fa4343','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[2][0].minT * 100)/100).toFixed(2)}°C </span>
          </Grid> <Grid item xs={1} style={{ backgroundColor: ' #76a6f6' }}>
            <Diameter component={Diameter} style={{ margin: 'auto'}} />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6'}}>
            <span style={{ backgroundColor: ' #76a6f6','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[3][0].avgF * 100)/100).toFixed(2)}% </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6' }}>
          <ArrowUpwardIcon />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6'}}>
            <span style={{ backgroundColor: ' #76a6f6','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[4][0].maxF * 100)/100).toFixed(2)}% </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6' }}>
          <ArrowDownwardIcon />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6'}}>
            <span style={{ backgroundColor: ' #76a6f6','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[5][0].minF * 100)/100).toFixed(2)}% </span>
          </Grid>
        </Grid>
      </div>

    );
  }
}
export class DashboardAusenTempSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: getAusenTemperaturSummary(this.props.number),
      scale: this.props.scale
    };
  }


  componentDidUpdate() {
    if (this.state.scale !== this.props.scale) {
      this.setState({
        scale: this.props.scale,
        data: getAusenTemperaturSummary(this.props.number)
      });
    }
  }

  componentDidMount() {
    window.setInterval(() => {
      this.setState({
        data: getAusenTemperaturSummary(this.props.number)
      });
    }, 60000)
  }

  render() {
    return (
      <div>
        <Grid container spacing={3} >
          <Grid item xs={1} style={{ backgroundColor: '#fa4343' }}>
            <Diameter component={Diameter} style={{ margin: 'auto'}} />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
            <span style={{ backgroundColor: '#fa4343','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[0][0].avgT * 100)/100).toFixed(2)}°C </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
          <span style={{ backgroundColor: '#fa4343','display': 'block' , 'marginTop': 'auto'}} >
            <ArrowUpwardIcon /></span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
            <span style={{ backgroundColor: '#fa4343','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[1][0].maxT * 100)/100).toFixed(2)}°C </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343' }}>
          <ArrowDownwardIcon />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: '#fa4343'}}>
            <span style={{ backgroundColor: '#fa4343','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[2][0].minT * 100)/100).toFixed(2)}°C </span>
          </Grid> <Grid item xs={1} style={{ backgroundColor: ' #76a6f6' }}>
            <Diameter component={Diameter} style={{ margin: 'auto'}} />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6'}}>
            <span style={{ backgroundColor: ' #76a6f6','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[3][0].avgF * 100)/100).toFixed(2)}% </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6' }}>
          <ArrowUpwardIcon />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6'}}>
            <span style={{ backgroundColor: ' #76a6f6','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[4][0].maxF * 100)/100).toFixed(2)}% </span>
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6' }}>
          <ArrowDownwardIcon />
          </Grid>
          <Grid item xs={1} style={{ backgroundColor: ' #76a6f6'}}>
            <span style={{ backgroundColor: ' #76a6f6','textAlign': 'center' , 'fontSize': '16px', 'verticalAlign': '10px'}} > {(Math.round(this.state.data[5][0].minF * 100)/100).toFixed(2)}% </span>
          </Grid>
        </Grid>
      </div>

    );
  }
}

export class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => new Date(d.time));
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.temperatur);
    this.myChart.data.datasets[1].data = this.props.data.map(d => d.feuchte);
    this.myChart.options.scales.xAxes[0].time.unit = this.props.scale;
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'line',
      options: {
        maintainAspectRatio: true,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour'
            }
          }],

          yAxes: [
            {
              ticks: {
              
              }
            }
          ]
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 0,
            bottom: 0
          }
        }
      },
      data: {
        labels: this.props.data.map(d => d.time),
        datasets: [{
          label: "Temperatur der letzten Stunden",
          data: this.props.data.map(d => d.temperatur),
          fill: 'none',
          backgroundColor: "#DF0101",
          pointRadius: 2,
          borderColor: "#DF0101",
          borderWidth: 1,
          lineTension: 0.1
        }, {
          label: "Luftfeuchte der letzten Stunden",
          data: this.props.data.map(d => d.feuchte),
          fill: 'none',
          backgroundColor: "#3273df",
          pointRadius: 2,
          borderColor: "#3273df",
          borderWidth: 1,
          lineTension: 0.2
        },
        ]
      }
    });
  }

  render() {
    return <canvas height="60%" ref={this.canvasRef} />;
  }
}

