export function getInnenTemperaturEntry(limit) {
    var xhr = new XMLHttpRequest()
    xhr.open('post', 'https://wetter.bgutschi.at/DbConnectors/selectInnenTemperatur.php', false);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send('lim=' + limit);
    return JSON.parse(xhr.responseText);
}

export function getAusenTemperaturEntry(limit) {
    var xhr = new XMLHttpRequest()
    xhr.open('post', 'https://wetter.bgutschi.at/DbConnectors/selectAusenTemperatur.php', false);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send('lim=' + limit);
    return JSON.parse(xhr.responseText);
}

export function getAusenTemperaturSummary(limit) {
    var xhr = new XMLHttpRequest()
    xhr.open('post', 'https://wetter.bgutschi.at/DbConnectors/selectAusenTemperaturSummary.php', false);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send('lim=' + limit);
    var responsesSplit = xhr.responseText.split(";");
    var responseArray = [];
    for (let i = 0; i < responsesSplit.length; i++) {
        try {
            responseArray.push(JSON.parse(responsesSplit[i]));
        }
        catch (e) {
            //  Block of code to handle errors
        }
    }
    return responseArray;
}

export function getInnenTemperaturSummary(limit) {
    var xhr = new XMLHttpRequest()
    xhr.open('post', 'https://wetter.bgutschi.at/DbConnectors/selectInnenTemperaturSummary.php', false);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send('lim=' + limit);
    var responsesSplit = xhr.responseText.split(";");
    var responseArray = [];
    for (let i = 0; i < responsesSplit.length; i++) {
        try {
            responseArray.push(JSON.parse(responsesSplit[i]));
        }
        catch (e) {
            //  Block of code to handle errors
        }
    }
    return responseArray;
}
